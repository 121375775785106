<template>
  <v-container>
    <v-row>
      <v-col class="text-center" cols="12" >
        <h1 class="display-1 font-weight-bold mb-3">
          <!-- Buchen Sie schnell und einfach Ihren Beratungstermin -->
          {{this.text_settings.title}}
        </h1>
      </v-col>

      <v-col class="mb-15" cols="12">
        <v-row>
          <v-col  cols="12" md="6" sm="6"  xs="12" class="text-center" >
            <!-- product image -->
            <img :src="''+this.$route.query.image" width="100%" style="max-width:400px">
            <!-- <img :src="'https://via.placeholder.com/'+this.$route.query.image"> -->
          </v-col>
          <v-col  cols="12" md="6" sm="6"  xs="12" >
            <!-- product information -->
            <div class="carTitle">
              {{this.$route.query.brand}}
              <br>
              <span>{{this.$route.query.model}}</span>
            </div>
            <div class="carPricing">
              <span>{{this.$route.query.price+ " €"}}</span>
            </div>
            <div class="carSummary">
              <span class="vehicleTitleInfo">
                <strong>{{this.$route.query.vorführfahrzeug}}</strong><br><br>
                <strong>Erstzulassung:</strong>
                {{this.$route.query.erstzulassung}}
                </span>
              <span class="vehicleTitleInfo"> 
                <strong>Kilometerstand:</strong>
                {{this.$route.query.kilometerstand}}
              </span>
              <!-- <span>{{this.$route.query.location}}</span> -->
              <span>{{this.car_data.car_location}}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="mb-15" cols="12">
        <h2 class="headline font-weight-bold mb-10">
          <!-- Wann möchten Sie uns besuchen? -->
          <!-- Wann passt es Ihnen? -->
          {{this.text_settings.appointment_select_text}}
        </h2>

        <v-row justify="center">
          <v-col cols="12" md="6" sm="6"  xs="12" class="custom-datepicker">
            <v-date-picker
              style="font-size: 16px"
              :min="current_date"
              :max="max_date"
              v-model="value_datePicker"
              :first-day-of-week="1"
              locale="de-DE"
            ></v-date-picker>
          </v-col>
          <v-col cols="12" md="6" sm="6"  xs="12">
            <v-row justify="start" align="center">
              <v-chip-group
                active-class="primary--text"
                v-model="value_selected_slot"
                column
              >
                <v-chip
                  large
                  class="ma-2"
                  :value="n"
                  filter
                  v-for="n in items_available_slots"
                  :key="n.title"
                  :disabled="check_slot_booked(n)"
                >
                  {{ n.title }}
                </v-chip>
              </v-chip-group>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "BookTestDrive",
  props:
    ['text_settings']
  ,
  methods: {
    ...mapActions([
      "get_car_type_model_details",
      "get_details",
      "get_car_details",
      "get_available_slot",
      "get_location_from_brand",
      "get_brands_from_location",
      "get_available_slot_consultation",
      "get_car_location"
    ]),
    prepare_car_type_model_details: function () {
      this.get_car_type_model_details().then((response) => {
        // this.car_type_model_details = response;
        // this.vehicle_details = response.vehicle_details;
        this.brand_details = response.brand_details;
        // this.vehicle_type_filter = response.vehicle_type_filter;
        // this.brand_details_filter = response.brand_details_filter;
        // this.enabled_brand_details = response.brand_details_filter;

        // this.items_model = response.filter_details.model;
        // this.items_transmission = response.filter_details.transmission;
        // this.locations = response.locations;
        this.booking_type = response.booking_type
        if (response.booking_type) {
          this.active = response.booking_type[0].name
        }
      });
    },
    prepare_car_location: function () {
      let car_location = {
            location_code: this.$route.query.location
          };
      this.get_car_location(car_location).then((response) => {
        
        this.car_data.car_location = response
        this.prepare_time_slots(response);
        // if (response.location) {
        // }else{
        //   // show error
        // }
      });
    },
    prepare_time_slots: function (location) {
      if (
        typeof this.car_data.car_location == "string"
      ) {
        // https://pf.mtsnetworks.de/api/method/carrental.api.get_available_slot_website_car_exchange
        let car_details = {
          location: location,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
        this.book_model_data.value_datePicker = this.value_datePicker;
      } else {
        this.book_model_data.value_datePicker = null;
      }
    },
    prepare_car_details: function () {
      this.get_car_details().then((response) => {
        this.vehicleResult = response;
      });
    },
    check_brand_enabled: function (brand) {
      if (typeof brand.enable !== "undefined") {
        if (brand.enable === 1) {
          return false;
        } else {
          return true;
        }
      }
    },
    check_slot_booked: function (slot) {
      if (slot.booked === 1) {
        return true;
      } else {
        return false;
      }
    },
    // setParamData: function () {
    //   this.car_data.car_brand_name = this.$route.query.brand;
    //   this.car_data.car_model = this.$route.query.model;
    //   // this.car_data.car_location = this.$route.query.location;
    //   // this.car_data.car_image_path = this.$route.query.image;
    // }
  },
  created(){
    this.prepare_car_type_model_details();
    this.prepare_car_details();
    this.prepare_car_location();
    // calculate max date allowed for booking calender
    var dt = new Date();
    dt.setDate(dt.getDate() + 3);
    this.max_date = dt.toISOString().substr(0, 10);
    // this.prepare_time_slots();
    // this.setParamData();
  },
  watch: {
    value_brand(newValue) {
      if(this.value_locations == null || this.value_locations == '') {
        console.log('yes')
      }
      // if(typeof this.value_locations != "string"){
        this.locations = []
          let brand_details = {
            brand: newValue.name
          };
        this.get_location_from_brand(brand_details).then((response) => {
          this.locations = response;
        });
      // }
      if (
        typeof this.value_brand == "object" &&
        this.value_locations != "" &&
        typeof this.value_locations == "string" &&
        typeof this.value_datePicker == "string"
      ) {
        let car_details = {
          brand: this.value_brand.name,
          location: this.value_locations,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
      }
    },
    value_locations() {
      if(typeof this.value_brand != "object"){
        this.brand_details = []
          let location_details = {
            location: this.value_locations
          };
        this.get_brands_from_location(location_details).then((response) => {
          this.brand_details = response;
        });
      }
  
      if (
        typeof this.value_brand == "object" &&
        this.value_locations != "" &&
        typeof this.value_locations == "string" &&
        typeof this.value_datePicker == "string"
      ) {
        let car_details = {
          brand: this.value_brand.name,
          location: this.value_locations,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
      }
    },
    value_datePicker(newValue) {
      this.value_selected_slot = "";
      this.items_available_slots = [];
      if (
        typeof this.car_data.car_location == "string"
      ) {
        // let booking_type_details = {
        //   date: this.value_datePicker,
        //   b_type: this.active
        // }
        // this.consulation_appointment_type_disable(booking_type_details).then((response) => {
        //   if (response) {
        //     if (response.warning_message != false){
        //       alert(response.warning_message);

        //     }
        //     this.online_disabled = response.online_disabled
        //     this.vor_ort_disabled = response.vor_ort_disabled
        //     this.active = response.active

        //   }
        // });
        // https://pf.mtsnetworks.de/api/method/carrental.api.get_available_slot_website_car_exchange
        let car_details = {
          location: this.car_data.car_location,
          date: this.value_datePicker
        };

        this.get_available_slot_consultation(car_details).then((response) => {
          this.items_available_slots = response;
        });
        this.book_model_data.value_datePicker = newValue;
      } else {
        this.book_model_data.value_datePicker = null;
      }
    },
    value_selected_slot(newValue) {
      // console.log(newValue.licence_plate);
      if (typeof this.value_selected_slot == "object") {
        this.book_model_data.value_selected_slot = newValue;
      } else {
        this.book_model_data.value_selected_slot = null;
      }
    },
    book_model_data: {
      handler() {
        if (
          // typeof this.value_type == "object" &&
          // typeof this.value_brand == "object" &&W
          // typeof this.value_brand == "object" &&
          typeof this.car_data.car_location == "string" &&
          // typeof this.value_model == "string" &&
          // typeof this.value_transmission == "string" &&
          // typeof this.value_type_of_fuel == "string" &&
          typeof this.value_selected_slot == "object"
        ) {
          this.$emit("allow-booking-form", false);
          var booking_data = {
            brand: this.$route.query.brand,
            model: this.$route.query.model,
            location: this.car_data.car_location,
            booking_date: this.value_datePicker,
            from_time: this.value_selected_slot.from_time,
            to_time: this.value_selected_slot.to_time,
            licence_plate: this.value_selected_slot.licence_plate,
            personal_online: this.active
          };
          this.$emit("booking-data", booking_data);
        } else {
          this.$emit("allow-booking-form", true);
        }
      },
      deep: true,
    },
  },
  data: () => ({
    base_url: "https://pf.mtsnetworks.de/",
    car_type_model_details: "",
    value_type: "",
    value_brand: "",
    value_locations:"",
    value_vehicle: "",
    current_date: new Date().toISOString().substr(0, 10),
    max_date:"",
    value_datePicker: new Date().toISOString().substr(0, 10),
    value_model: null,
    value_transmission: null,
    value_type_of_fuel: null,
    value_selected_slot: "",
    vehicle_type_filter: "",
    brand_details_filter: "",
    vehicle_details: "",
    brand_details: null,
    vehicleResult: [],
    items_model: [],
    items_transmission: [],
    items_type_of_fuel: [],
    items_available_slots: [],
    book_model_data: {
      value_type: "",
      value_brand: "",
      value_vehicle: "",
      value_datePicker: new Date().toISOString().substr(0, 10),
      value_model: "",
      value_transmission: "",
      value_type_of_fuel: "",
      value_selected_slot: "",
    },
    booking_type: null,
    locations:null,
    active: 'Online',
    vor_ort_disabled: false,
    online_disabled: false,
    car_data:{
      // car_brand_name: "",
      // car_model: "",
      car_location: "",
      // car_image_path: ""
    }
  }),
};
</script>


<style>
.custom-datepicker .v-date-picker-table .v-btn {
  font-size: 16px;
}
.v-list-item__title{
  text-transform: uppercase;
}
.v-slide-group__content .v-card--link:before, .v-card .v-card--link:before{
  background: white;
}
.v-slide-group__content .theme--light.v-sheet--outlined , .v-card .theme--light.v-sheet--outlined{
    border-color: #1976d2;
    border-width: 2px;
}
.location-label{
  color: #1976d2;
}
.book-type .v-chip.v-size--large {
  border-radius:0
}
/* .location-type .v-chip.v-size--large { */
  /* border-radius:0; */
  /* border: 1px solid black;
  background: white */
/* } */

/* 26-04-21 */
.carTitle {
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 1;
    position: relative;
    text-transform: uppercase;
}
.carTitle span {
    font-weight: 300;
    font-size: 22px;
    text-transform: initial;
}
.carPricing span:first-child {
    font-size: 32px;
    font-weight: 600;
    margin: 0px;
    line-height: 40px;
}
.carPricing{
    margin-bottom: 20px;
}
.carSummary .vehicleTitleInfo strong {
    font-size: 18px;
    display: inline;
    color: #013861;
}
.carSummary .vehicleTitleInfo {
    display: block;
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
    line-height: 24px;
}
</style>