<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1">
        <!-- Marke und Termin wählen -->
        {{this.text_settings.step_1_text}}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2">
        <!-- Kundendaten eingeben -->
        {{this.text_settings.step_2_text}}
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="3"> {{this.text_settings.step_3_text}} </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12" color=" lighten-1" flat>
          <BookTestDrive
            @allow-booking-form="allow_booking_form"
            @booking-data="get_booking_data"
            :text_settings=this.text_settings
          />
          <v-card class="d-flex justify-center" flat>
            <v-btn color="primary" :disabled="booking_form" @click="showBookingForm()">
              {{this.text_settings.button_step_1_text}}
            </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-12" color=" lighten-1" flat>
          <BookingForm
            @booking-submit="submit_booking"
            @booking-back="e1 = 1"
            :set_booking_data="booking_data"
          />
        </v-card>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-card class="d-flex justify-center flat" height="500"> 
          <v-card class="align-self-center" flat>
            <v-alert prominent type="success" color="primary" >
                {{this.text_settings.success_message}}
            </v-alert>
            <v-btn href="https://mts-mobile.de/fahrzeuge" class="center">
              Zurück zur Fahrzeugbörse
            </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import BookTestDrive from "./BookTestDrive";
import BookingForm from "./BookingForm";


export default {
  name: "Stepper",
  props:
    ['text_settings']
  ,
  components: {
    BookTestDrive,
    BookingForm,
  },
  methods: {
    allow_booking_form: function (e) {
      this.booking_form = e;
    },
    get_booking_data: function (data) {
      this.booking_data = data;
    },
    showBookingForm() {
      this.e1 = 2
      window.scrollTo(0,0);
    },
    submit_booking: function (result) {
      if (result) {
        this.e1 = 3;
      } else {
        alert("Bitte versuche es erneut");
      }
    },
  },
  data() {
    return {
      e1: 1,
      booking_form: true,
      booking_data: null
    };
  },
};
</script>
