<template>
  <v-container>
    <v-row>
      <v-col>
        <form cols="12" sm="12">
          <v-text-field
            v-model="first_name"
            :error-messages="first_nameErrors"
            label="Vorname"
            class="required-field"
            required
            @input="$v.first_name.$touch()"
            @blur="$v.first_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="last_name"
            :error-messages="last_nameErrors"
            class="required-field"
            label="Nachname"
            required
            @input="$v.last_name.$touch()"
            @blur="$v.last_name.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail"
            class="required-field"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="street"
            :error-messages="streetErrors"
            class="required-field"
            label="Straße"
            required
            @input="$v.street.$touch()"
            @blur="$v.street.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="city"
            :error-messages="cityErrors"
            class="required-field"
            label="Ort"
            required
            @input="$v.city.$touch()"
            @blur="$v.city.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="postal_code"
            :error-messages="postal_codeErrors"
            class="required-field"
            label="PLZ"
            required
            @input="$v.postal_code.$touch()"
            @blur="$v.postal_code.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_private"
            label="Telefon (privat)"
            required
            @blur="$v.phone_private.$touch()"
            @input="$v.phone_private.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_mobile"
            :error-messages="phone_mobileErrors"
            class="required-field"
            label="Handy"
            required
            @input="$v.phone_mobile.$touch()"
            @blur="$v.phone_mobile.$touch()"
          ></v-text-field>
          <v-text-field
            v-model="phone_home"
            label="Telefon (geschäftlich)"
            required
            @input="$v.phone_home.$touch()"
            @blur="$v.phone_home.$touch()"
          ></v-text-field>

           <!-- <v-select
              :items="this.items_locations"
              :error-messages="value_locationErrors"
              v-model="value_location"
              label="Standort"
              class="required-field"
              @input="$v.value_location.$touch()"
            >
              <template slot="no-data">
                <v-list-item>keine Einträge</v-list-item>  
              </template>
            </v-select> -->
          <v-label><strong>Ihr Fahrzeug steht an folgendem Standort bereit : </strong></v-label><br>
          <v-label>{{this.set_booking_data.location}}</v-label>

          <v-checkbox v-model="accepted" required
            @input="$v.accepted.$touch()"
            :value="true"
            @click="$v.accepted.$touch()"
            :error-messages="acceptedErrors" >
            <template v-slot:label>
              <div>
                Hiermit akzeptiere ich die Datenschutzerklärung unter 
                    <a
                      target="_blank"
                      href="http://www.mts-mobile.de/datenschutz"
                      @click.stop                      
                    >
                      www.mts-mobile.de/datenschutz
                    </a>
              </div>
            </template>
            
          </v-checkbox>

          
          <v-btn class="ma-2" @click="submit"> Jetzt Reservieren </v-btn>
          <v-btn class="ma-2" @click="back"> Zurück zur Terminauswahl </v-btn>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: "BookingForm",
  mixins: [validationMixin],
  props:
    // ['set_booking_data']
    ['set_booking_data']
  ,
  watch: { 
    // set_booking_data: function(booking_data) { // watch it
    //   // this.prepare_location_details(booking_data.car);
    // }
  },
  validations: {
    first_name: { required  },
    last_name: { required},
    email: { required, email },
    street: { required },
    city: { required },
    postal_code: { required },
    // phone_private: {  },
    phone_mobile: { required },
    // phone_home: { },
    accepted: {required}
    // value_location: {required},
  },
  methods: {
    ...mapActions([
      "book_appointment","get_location",
      "book_appointment_consultation"
    ]),
    prepare_location_details: function (car) {
      let car_details = {
          car: car
        };
      
      this.get_location(car_details).then((response) => {
        this.items_locations = response;
      });
    },
    submit(e) {
      e.preventDefault();
      this.$v.$touch();
      if (this.$v.$invalid) {
       
        return;
      } else {
        //create form data object
        const post_data = new FormData();
        var post_json = {
            brand: this.set_booking_data.brand,
            model: this.set_booking_data.model,
            location:this.set_booking_data.location,
            first_name:this.first_name,
            last_name:this.last_name,
            booking_date:this.set_booking_data.booking_date,
            from_time:this.set_booking_data.from_time,
            to_time:this.set_booking_data.to_time,
            licence_plate:this.set_booking_data.licence_plate,
            email:this.email,
            street:this.street,
            city:this.city,
            postal_code:this.postal_code,
            phone_private:this.phone_private,
            phone_mobile:this.phone_mobile,
            phone_home:this.phone_home,
            is_accepted: this.accepted,
            personal_online: this.set_booking_data.personal_online,
            url: this.$route.query.url
            // location: this.value_location
        };
        post_data.append("appointment_data", JSON.stringify(post_json));
        setTimeout(() => {
          this.book_appointment_consultation(post_data)
            .then((response) => {
              if(response){
                this.$emit('booking-submit', true);
              }else{
                this.$emit('booking-submit', false);
              }
            })
            .catch(() => {
              this.$emit('booking-submit', false);
            });
        });
      }
    },
    back(){
      this.$emit('booking-back', true);
    }
  },
  computed: {
    first_nameErrors() {
      const errors = [];
      if (!this.$v.first_name.$dirty) return errors;
      !this.$v.first_name.required && errors.push("Pflichtfeld.");
      return errors;
    },
    last_nameErrors() {
      const errors = [];
      if (!this.$v.last_name.$dirty) return errors;
      !this.$v.last_name.required && errors.push("Pflichtfeld.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Muss eine gültige E-Mail sein");
      !this.$v.email.required && errors.push("E-Mail ist ein Pflichtfeld");
      return errors;
    },
    streetErrors() {
      const errors = [];
      if (!this.$v.street.$dirty) return errors;
      !this.$v.street.required && errors.push("Pflichtfeld.");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.city.$dirty) return errors;
      !this.$v.city.required && errors.push("Pflichtfeld.");
      return errors;
    },
    postal_codeErrors() {
      const errors = [];
      if (!this.$v.postal_code.$dirty) return errors;
      !this.$v.postal_code.required && errors.push("Pflichtfeld.");
      return errors;
    },
    phone_mobileErrors() {
      const errors = [];
      if (!this.$v.phone_mobile.$dirty) return errors;
      !this.$v.phone_mobile.required && errors.push("Pflichtfeld.");
      return errors;
    },  
    // value_locationErrors() {
    //   const errors = [];
    //   if (!this.$v.value_location.$dirty) return errors;
    //   !this.$v.value_location.required && errors.push("Pflichtfeld.");
    //   return errors;
    // },  
    acceptedErrors() {
      const errors = [];
      if (!this.$v.accepted.$dirty) return errors;
      !this.$v.accepted.required && errors.push("Pflichtfeld.");
      return errors;
    },  
    
  },
  data: () => ({
      first_name: null,
      last_name: null,
      email: null,
      street: null,
      city: null,
      postal_code: null,
      phone_private: null,
      phone_mobile: null,
      phone_home: null,
      accepted: null,
      // value_location: null,
      items_locations:null
  }),
};
</script>


<style>
.required-field.v-input .v-label::after {
  content: " *";
  color: red;
}
</style>